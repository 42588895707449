import { gql } from '@apollo/client';

export const fetchBIReportAccessQuery = gql`
  query getBIReportAccess(
    $oktaId: String!
    $clubId: String
    $districtId: String
  ) {
    access: getBIReportAccess(
      oktaId: $oktaId
      clubId: $clubId
      districtId: $districtId
    )
  }
`;
