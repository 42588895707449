import { useQuery } from '@apollo/client';

import { fetchReportOrgOptionsQuery } from '../queries';

import {
  GetBiReportOrgOptionsQuery,
  GetBiReportOrgOptionsQueryVariables,
} from '@typings/operations';

export const useFetchReportOrgOptions = (
  dataSelector: string,
  oktaId: string
) =>
  useQuery<GetBiReportOrgOptionsQuery, GetBiReportOrgOptionsQueryVariables>(
    fetchReportOrgOptionsQuery,
    { variables: { dataSelector, oktaId } }
  );
