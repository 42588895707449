import { gql } from '@apollo/client';

const RosterResultRow = gql`
  fragment RosterResultRow on MemberSearchResult {
    id
    riIndividualId
    membershipId
    name
    email
    localizedName
    clubLeadership: thisClubLeadership(clubId: $clubId) {
      role
      startDate
      endDate
      id
      roleId
    }
    leadershipsFromThisClub: activeLeaderships(clubId: $clubId) {
      role
      startDate
      endDate
      id
      roleId
    }
    photoUri
    membershipsFromThisClub: activeMemberships(clubId: $clubId) {
      type
      admissionDate
      terminationDate
      clubId
    }
    clubMemberships: clubMemberships(clubId: $clubId) {
      type
      admissionDate
      terminationDate
    }
    activeMembershipInAllDistricts: activeMembershipInAllDistricts(
      clubId: $clubId
    ) {
      type
      admissionDate
      terminationDate
      clubId
    }

    associatedClubsInfo {
      clubId
      clubName
      clubType
      physicalLocation {
        country
        city
        state
        internationalProvince
      }
    }

    phoneNumber
    isDeceased
    deceasedDate
    operationsAccess {
      assignRole
      editMember
      editMembershipType
      terminateMembership
    }
  }
`;

export const fetchClubMembersQuery = gql`
  query ClubRoster(
    $clubId: String!
    $name: String = ""
    $membershipStatuses: [MembershipStatusFilter] = []
    $email: String = ""
    $roles: [String] = []
    $page: Int = 1
    $pageSize: Int = 10
    $isMultifilterAllowed: Boolean = false
  ) {
    club: clubById(clubId: $clubId) {
      clubType
      clubName
      riClubId
      district
      operationsAccess {
        club
        financesGoalsReports
        memberships
        leaderships
      }
      members(
        name: $name
        email: $email
        membershipStatuses: $membershipStatuses
        roles: $roles
        pageSize: $pageSize
        page: $page
        isMultifilterAllowed: $isMultifilterAllowed
      ) {
        totalCount
        results {
          ...RosterResultRow
        }
      }
    }
  }
  ${RosterResultRow}
`;
