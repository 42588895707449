import { gql } from '@apollo/client';

export const fetchReportOrgOptionsQuery = gql`
  query getBIReportOrgOptions($dataSelector: String!, $oktaId: String!) {
    options: getBIReportOrgOptions(
      dataSelector: $dataSelector
      oktaId: $oktaId
    ) {
      matchType
      clubId
      clubName
      districtId
      roleName
      regionalGroupId
      regionalGroupName
      termStartDate
      termEndDate
    }
  }
`;
