import { useQuery } from '@apollo/client';

import {
  GetBiReportAccessQuery,
  GetBiReportAccessQueryVariables,
} from '@typings/operations';
import { fetchBIReportAccessQuery } from '../queries';

export const useFetchBIReportAccess = (
  oktaId: string,
  clubId: string | null,
  districtId: string | null
) =>
  useQuery<GetBiReportAccessQuery, GetBiReportAccessQueryVariables>(
    fetchBIReportAccessQuery,
    {
      variables: {
        oktaId,
        clubId,
        districtId,
      },
    }
  );
